/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BundleCheckoutResponse } from '../models/bundle-checkout-response';
import { CarrierDeliveryNoteBundle } from '../models/carrier-delivery-note-bundle';
import { CarrierDeliveryNoteBundlesOverview } from '../models/carrier-delivery-note-bundles-overview';
import { ConsigneeDeliveryNoteBundle } from '../models/consignee-delivery-note-bundle';
import { deleteBundle } from '../fn/delivery-note-bundles/delete-bundle';
import { DeleteBundle$Params } from '../fn/delivery-note-bundles/delete-bundle';
import { DeliveryNoteBundle } from '../models/delivery-note-bundle';
import { DeliveryNoteBundlesOverview } from '../models/delivery-note-bundles-overview';
import { getBundle } from '../fn/delivery-note-bundles/get-bundle';
import { GetBundle$Params } from '../fn/delivery-note-bundles/get-bundle';
import { getBundles } from '../fn/delivery-note-bundles/get-bundles';
import { GetBundles$Params } from '../fn/delivery-note-bundles/get-bundles';
import { getCarrierBundle } from '../fn/delivery-note-bundles/get-carrier-bundle';
import { GetCarrierBundle$Params } from '../fn/delivery-note-bundles/get-carrier-bundle';
import { getCarrierBundles } from '../fn/delivery-note-bundles/get-carrier-bundles';
import { GetCarrierBundles$Params } from '../fn/delivery-note-bundles/get-carrier-bundles';
import { getConsigneeBundle } from '../fn/delivery-note-bundles/get-consignee-bundle';
import { GetConsigneeBundle$Params } from '../fn/delivery-note-bundles/get-consignee-bundle';
import { getConsigneeBundles } from '../fn/delivery-note-bundles/get-consignee-bundles';
import { GetConsigneeBundles$Params } from '../fn/delivery-note-bundles/get-consignee-bundles';
import { getConsigneeBundlesForCheckin } from '../fn/delivery-note-bundles/get-consignee-bundles-for-checkin';
import { GetConsigneeBundlesForCheckin$Params } from '../fn/delivery-note-bundles/get-consignee-bundles-for-checkin';
import { GetConsigneeDeliveryNoteBundlesCheckinResponse } from '../models/get-consignee-delivery-note-bundles-checkin-response';
import { patchBundle } from '../fn/delivery-note-bundles/patch-bundle';
import { PatchBundle$Params } from '../fn/delivery-note-bundles/patch-bundle';
import { PostBundleResponse } from '../models/post-bundle-response';
import { postDeliveryNoteBundlesCheckout } from '../fn/delivery-note-bundles/post-delivery-note-bundles-checkout';
import { PostDeliveryNoteBundlesCheckout$Params } from '../fn/delivery-note-bundles/post-delivery-note-bundles-checkout';
import { postMergeBundles } from '../fn/delivery-note-bundles/post-merge-bundles';
import { PostMergeBundles$Params } from '../fn/delivery-note-bundles/post-merge-bundles';
import { postNewBundle } from '../fn/delivery-note-bundles/post-new-bundle';
import { PostNewBundle$Params } from '../fn/delivery-note-bundles/post-new-bundle';


/**
 * Endpoints to handle bundles
 */
@Injectable({ providedIn: 'root' })
export class DeliveryNoteBundlesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getBundles()` */
  static readonly GetBundlesPath = '/organization-sites/{organizationSiteKey}/consignor/delivery-note-bundles';

  /**
   * Get bundle list as consignor.
   *
   * Get overview list of all delivery note bundles.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBundles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBundles$Response(params: GetBundles$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliveryNoteBundlesOverview>> {
    return getBundles(this.http, this.rootUrl, params, context);
  }

  /**
   * Get bundle list as consignor.
   *
   * Get overview list of all delivery note bundles.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBundles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBundles(params: GetBundles$Params, context?: HttpContext): Observable<DeliveryNoteBundlesOverview> {
    return this.getBundles$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliveryNoteBundlesOverview>): DeliveryNoteBundlesOverview => r.body)
    );
  }

  /** Path part for operation `postNewBundle()` */
  static readonly PostNewBundlePath = '/organization-sites/{organizationSiteKey}/consignor/delivery-note-bundles';

  /**
   * Create bundle.
   *
   * Create new delivery note bundle.
   * The consignor key in the payload and the path parameter
   * organizationSiteKey must be equal.
   * The carrierKey and consigneeKey are the organizationSiteKeys
   * of the parties in the supply chain.
   * So the consignor site is the site where the bundle will be created in.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postNewBundle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postNewBundle$Response(params: PostNewBundle$Params, context?: HttpContext): Observable<StrictHttpResponse<PostBundleResponse>> {
    return postNewBundle(this.http, this.rootUrl, params, context);
  }

  /**
   * Create bundle.
   *
   * Create new delivery note bundle.
   * The consignor key in the payload and the path parameter
   * organizationSiteKey must be equal.
   * The carrierKey and consigneeKey are the organizationSiteKeys
   * of the parties in the supply chain.
   * So the consignor site is the site where the bundle will be created in.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postNewBundle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postNewBundle(params: PostNewBundle$Params, context?: HttpContext): Observable<PostBundleResponse> {
    return this.postNewBundle$Response(params, context).pipe(
      map((r: StrictHttpResponse<PostBundleResponse>): PostBundleResponse => r.body)
    );
  }

  /** Path part for operation `getBundle()` */
  static readonly GetBundlePath = '/organization-sites/{organizationSiteKey}/consignor/delivery-note-bundles/{deliveryNoteBundleKey}';

  /**
   * Get a bundle by key.
   *
   * Get one delivery note bundle by delivery note bundle key.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBundle()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBundle$Response(params: GetBundle$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliveryNoteBundle>> {
    return getBundle(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a bundle by key.
   *
   * Get one delivery note bundle by delivery note bundle key.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBundle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBundle(params: GetBundle$Params, context?: HttpContext): Observable<DeliveryNoteBundle> {
    return this.getBundle$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliveryNoteBundle>): DeliveryNoteBundle => r.body)
    );
  }

  /** Path part for operation `deleteBundle()` */
  static readonly DeleteBundlePath = '/organization-sites/{organizationSiteKey}/consignor/delivery-note-bundles/{deliveryNoteBundleKey}';

  /**
   * Delete bundle.
   *
   * Delete one delivery note bundle by delivery note bundle key if bundle status is 'open'.
   * The delivery notes (with attachments and load carriers) remain in the pool.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteBundle()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBundle$Response(params: DeleteBundle$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteBundle(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete bundle.
   *
   * Delete one delivery note bundle by delivery note bundle key if bundle status is 'open'.
   * The delivery notes (with attachments and load carriers) remain in the pool.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteBundle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBundle(params: DeleteBundle$Params, context?: HttpContext): Observable<void> {
    return this.deleteBundle$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `patchBundle()` */
  static readonly PatchBundlePath = '/organization-sites/{organizationSiteKey}/consignor/delivery-note-bundles/{deliveryNoteBundleKey}';

  /**
   * Update data of open bundle.
   *
   * Update name and supply chain of a delivery note bundle.
   * The bundle must be in status 'open'.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchBundle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchBundle$Response(params: PatchBundle$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return patchBundle(this.http, this.rootUrl, params, context);
  }

  /**
   * Update data of open bundle.
   *
   * Update name and supply chain of a delivery note bundle.
   * The bundle must be in status 'open'.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchBundle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchBundle(params: PatchBundle$Params, context?: HttpContext): Observable<void> {
    return this.patchBundle$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `postMergeBundles()` */
  static readonly PostMergeBundlesPath = '/organization-sites/{organizationSiteKey}/consignor/delivery-note-bundles-merge';

  /**
   * Merge open bundles.
   *
   * Merge multiple delivery note bundles into a new one. All bundles
   * must have status 'open'. The supply chain and name are taken from
   * the first bundle in the array
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postMergeBundles()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postMergeBundles$Response(params: PostMergeBundles$Params, context?: HttpContext): Observable<StrictHttpResponse<PostBundleResponse>> {
    return postMergeBundles(this.http, this.rootUrl, params, context);
  }

  /**
   * Merge open bundles.
   *
   * Merge multiple delivery note bundles into a new one. All bundles
   * must have status 'open'. The supply chain and name are taken from
   * the first bundle in the array
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postMergeBundles$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postMergeBundles(params: PostMergeBundles$Params, context?: HttpContext): Observable<PostBundleResponse> {
    return this.postMergeBundles$Response(params, context).pipe(
      map((r: StrictHttpResponse<PostBundleResponse>): PostBundleResponse => r.body)
    );
  }

  /** Path part for operation `getCarrierBundles()` */
  static readonly GetCarrierBundlesPath = '/organization-sites/{organizationSiteKey}/carrier/delivery-note-bundles';

  /**
   * Get bundle list as carrier.
   *
   * Get overview list of all delivery note bundles for the carrier.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCarrierBundles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCarrierBundles$Response(params: GetCarrierBundles$Params, context?: HttpContext): Observable<StrictHttpResponse<CarrierDeliveryNoteBundlesOverview>> {
    return getCarrierBundles(this.http, this.rootUrl, params, context);
  }

  /**
   * Get bundle list as carrier.
   *
   * Get overview list of all delivery note bundles for the carrier.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCarrierBundles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCarrierBundles(params: GetCarrierBundles$Params, context?: HttpContext): Observable<CarrierDeliveryNoteBundlesOverview> {
    return this.getCarrierBundles$Response(params, context).pipe(
      map((r: StrictHttpResponse<CarrierDeliveryNoteBundlesOverview>): CarrierDeliveryNoteBundlesOverview => r.body)
    );
  }

  /** Path part for operation `getCarrierBundle()` */
  static readonly GetCarrierBundlePath = '/organization-sites/{organizationSiteKey}/carrier/delivery-note-bundles/{deliveryNoteBundleKey}';

  /**
   * Get bundle for checkin.
   *
   * Get one delivery note bundle for a checkin.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCarrierBundle()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCarrierBundle$Response(params: GetCarrierBundle$Params, context?: HttpContext): Observable<StrictHttpResponse<CarrierDeliveryNoteBundle>> {
    return getCarrierBundle(this.http, this.rootUrl, params, context);
  }

  /**
   * Get bundle for checkin.
   *
   * Get one delivery note bundle for a checkin.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCarrierBundle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCarrierBundle(params: GetCarrierBundle$Params, context?: HttpContext): Observable<CarrierDeliveryNoteBundle> {
    return this.getCarrierBundle$Response(params, context).pipe(
      map((r: StrictHttpResponse<CarrierDeliveryNoteBundle>): CarrierDeliveryNoteBundle => r.body)
    );
  }

  /** Path part for operation `postDeliveryNoteBundlesCheckout()` */
  static readonly PostDeliveryNoteBundlesCheckoutPath = '/organization-sites/{organizationSiteKey}/consignor/delivery-note-bundles-checkout';

  /**
   * Checkout one or more delivery note bundles.
   *
   * Checkout one or more delivery note bundles. All bundles must be in status 'open'.
   * The checkout is only possible when every bundle to checkout has at least one delivery note.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postDeliveryNoteBundlesCheckout()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postDeliveryNoteBundlesCheckout$Response(params: PostDeliveryNoteBundlesCheckout$Params, context?: HttpContext): Observable<StrictHttpResponse<BundleCheckoutResponse>> {
    return postDeliveryNoteBundlesCheckout(this.http, this.rootUrl, params, context);
  }

  /**
   * Checkout one or more delivery note bundles.
   *
   * Checkout one or more delivery note bundles. All bundles must be in status 'open'.
   * The checkout is only possible when every bundle to checkout has at least one delivery note.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postDeliveryNoteBundlesCheckout$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postDeliveryNoteBundlesCheckout(params: PostDeliveryNoteBundlesCheckout$Params, context?: HttpContext): Observable<BundleCheckoutResponse> {
    return this.postDeliveryNoteBundlesCheckout$Response(params, context).pipe(
      map((r: StrictHttpResponse<BundleCheckoutResponse>): BundleCheckoutResponse => r.body)
    );
  }

  /** Path part for operation `getConsigneeBundles()` */
  static readonly GetConsigneeBundlesPath = '/organization-sites/{organizationSiteKey}/consignee/delivery-note-bundles';

  /**
   * Get bundle list as consignee.
   *
   * Get overview list of all delivery note bundles for the consignee.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeBundles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeBundles$Response(params: GetConsigneeBundles$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliveryNoteBundlesOverview>> {
    return getConsigneeBundles(this.http, this.rootUrl, params, context);
  }

  /**
   * Get bundle list as consignee.
   *
   * Get overview list of all delivery note bundles for the consignee.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeBundles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeBundles(params: GetConsigneeBundles$Params, context?: HttpContext): Observable<DeliveryNoteBundlesOverview> {
    return this.getConsigneeBundles$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliveryNoteBundlesOverview>): DeliveryNoteBundlesOverview => r.body)
    );
  }

  /** Path part for operation `getConsigneeBundle()` */
  static readonly GetConsigneeBundlePath = '/organization-sites/{organizationSiteKey}/consignee/delivery-note-bundles/{deliveryNoteBundleKey}';

  /**
   * Get bundle for check in.
   *
   * Get one delivery note bundle for a checkin.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeBundle()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeBundle$Response(params: GetConsigneeBundle$Params, context?: HttpContext): Observable<StrictHttpResponse<ConsigneeDeliveryNoteBundle>> {
    return getConsigneeBundle(this.http, this.rootUrl, params, context);
  }

  /**
   * Get bundle for check in.
   *
   * Get one delivery note bundle for a checkin.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeBundle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeBundle(params: GetConsigneeBundle$Params, context?: HttpContext): Observable<ConsigneeDeliveryNoteBundle> {
    return this.getConsigneeBundle$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConsigneeDeliveryNoteBundle>): ConsigneeDeliveryNoteBundle => r.body)
    );
  }

  /** Path part for operation `getConsigneeBundlesForCheckin()` */
  static readonly GetConsigneeBundlesForCheckinPath = '/organization-sites/{organizationSiteKey}/consignee/delivery-note-bundles/checkin';

  /**
   * Get bundles via token for checkin.
   *
   * Get delivery note bundles for a checkin via access token
   * from a wallet.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeBundlesForCheckin()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeBundlesForCheckin$Response(params: GetConsigneeBundlesForCheckin$Params, context?: HttpContext): Observable<StrictHttpResponse<GetConsigneeDeliveryNoteBundlesCheckinResponse>> {
    return getConsigneeBundlesForCheckin(this.http, this.rootUrl, params, context);
  }

  /**
   * Get bundles via token for checkin.
   *
   * Get delivery note bundles for a checkin via access token
   * from a wallet.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeBundlesForCheckin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeBundlesForCheckin(params: GetConsigneeBundlesForCheckin$Params, context?: HttpContext): Observable<GetConsigneeDeliveryNoteBundlesCheckinResponse> {
    return this.getConsigneeBundlesForCheckin$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetConsigneeDeliveryNoteBundlesCheckinResponse>): GetConsigneeDeliveryNoteBundlesCheckinResponse => r.body)
    );
  }

}
