<div
    *ngIf="initialized"
    class="xl:tw-px-5">
    <mat-expansion-panel
        class="mat-elevation-z0"
        [expanded]="carrierExchangeInitialOpen"
        [togglePosition]="'before'">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{
                    'OUTGOING-GOODS.NEW-BUNDLE.SWAP.LOAD-CARRIER-FOR-DELIVERY-NOTE'
                        | translate
                }}
                {{ deliveryNote.number }}
            </mat-panel-title>
            <mat-panel-description>
                <mat-icon
                    *ngIf="formSaved"
                    fontSet="material-symbols-outlined"
                    >done</mat-icon
                >
                <div
                    *ngIf="
                        !formSaved &&
                        loadCarrierSwapForm.valid &&
                        carrierExchangesFormArray.controls.length
                    "
                    class="tw-flex tw-w-[190px] tw-items-center tw-text-red-500">
                    <mat-icon
                        class="tw-min-w-[30px]"
                        fontSet="material-symbols-outlined"
                        >warning</mat-icon
                    >
                    {{ 'OUTGOING-GOODS.NEW-BUNDLE.SWAP.UNSAVED' | translate }}
                </div>
                <div
                    *ngIf="!formSaved && !loadCarrierSwapForm.valid"
                    class="tw-flex tw-w-[190px] tw-items-center tw-text-red-500">
                    <mat-icon
                        class="tw-min-w-[30px]"
                        fontSet="material-symbols-outlined"
                        >report</mat-icon
                    >
                    {{
                        'OUTGOING-GOODS.NEW-BUNDLE.SWAP.MISSING-FIELDS'
                            | translate
                    }}
                </div>
            </mat-panel-description>
        </mat-expansion-panel-header>

        <ng-container>
            <form
                [formGroup]="loadCarrierSwapForm"
                (ngSubmit)="saveLoadCarrier()">
                <div
                    [formArrayName]="nameOfControl"
                    *ngFor="
                        let carrierExchangeControl of carrierExchangesFormArray.controls;
                        let i = index
                    ">
                    <div
                        [formGroupName]="i"
                        class="tw-grid-rows-auto tw-grid tw-grid-cols-12 tw-items-center tw-gap-x-6 xl:tw-grid-rows-1">
                        <div class="tw-col-span-4 xl:tw-col-span-3">
                            <mat-form-field
                                appearance="fill"
                                class="mat-form-field-fix-pd">
                                <mat-label>{{
                                    'OUTGOING-GOODS.NEW-BUNDLE.SWAP.SELECT-LOAD-CARRIER'
                                        | translate
                                }}</mat-label>
                                <mat-select
                                    id="select-carrier"
                                    aria-label="select-carrier-type"
                                    formControlName="templateKey">
                                    <mat-option
                                        *ngFor="let carrier of allCarrierTypes"
                                        aria-label="select-carrier-type-option"
                                        [value]="carrier._key">
                                        {{ carrier.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="tw-col-span-2 xl:tw-col-span-1">
                            <mat-form-field
                                appearance="fill"
                                class="mat-form-field-fix-pd">
                                <mat-label>{{
                                    'OUTGOING-GOODS.NEW-BUNDLE.SWAP.QUANTITY'
                                        | translate
                                }}</mat-label>
                                <input
                                    matInput
                                    id="input-count-exchange-{{ i }}"
                                    aria-label="input-count-exchange-quantity"
                                    type="number"
                                    min="0"
                                    oninput="this.value = Math.abs(this.value)"
                                    formControlName="quantity"
                                    [autocomplete]="
                                        env.production ? 'off' : 'auto'
                                    "
                                    required />
                            </mat-form-field>
                        </div>
                        <div class="tw-col-span-4 xl:tw-col-span-3">
                            <mat-button-toggle-group
                                aria-label="Operation"
                                formControlName="operation">
                                <mat-button-toggle
                                    [value]="carrierOperations.Adoption"
                                    >{{
                                        'OUTGOING-GOODS.NEW-BUNDLE.SWAP.ADOPTION'
                                            | translate
                                    }}</mat-button-toggle
                                >
                                <mat-button-toggle
                                    [value]="carrierOperations.Delivery"
                                    >{{
                                        'OUTGOING-GOODS.NEW-BUNDLE.SWAP.DELIVERY'
                                            | translate
                                    }}</mat-button-toggle
                                >
                            </mat-button-toggle-group>
                        </div>
                        <div class="tw-col-span-3 xl:tw-col-span-2">
                            <mat-checkbox
                                class="me-2"
                                formControlName="damaged"
                                >{{
                                    'OUTGOING-GOODS.NEW-BUNDLE.SWAP.DAMAGED-LOAD-CARRIER'
                                        | translate
                                }}</mat-checkbox
                            >
                        </div>
                        <div class="tw-col-span-4 xl:tw-col-span-2">
                            <mat-form-field
                                appearance="fill"
                                class="mat-form-field-fix-pd">
                                <mat-label>{{
                                    'OUTGOING-GOODS.NEW-BUNDLE.SWAP.DESCRIPTION'
                                        | translate
                                }}</mat-label>
                                <input
                                    matInput
                                    id="input-notice-{{ i }}"
                                    type="text"
                                    min="0"
                                    maxlength="500"
                                    formControlName="notice" />
                                <span
                                    class="tw-text-red-500"
                                    [hidden]="
                                        !(
                                            carrierExchangeControl.get(
                                                'notice'
                                            )!.value.length >= 450
                                        )
                                    ">
                                    {{
                                        carrierExchangeControl.get('notice')!
                                            .value.length
                                    }}
                                    / 500
                                </span>
                            </mat-form-field>
                        </div>
                        <div class="tw-col-span-1 xl:tw-col-span-1">
                            <button
                                mat-icon-button
                                [matTooltip]="
                                    'OUTGOING-GOODS.NEW-BUNDLE.SWAP.REMOVE-LOAD-CARRIER'
                                        | translate
                                "
                                aria-label="remove-carrier-exchange"
                                (click)="removeCarrierExchange(i)">
                                <mat-icon fontSet="material-symbols-outlined"
                                    >delete</mat-icon
                                >
                            </button>
                        </div>
                    </div>
                    <hr class="tw-block xl:tw-hidden" />
                </div>
                <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                        <div class="tw-flex tw-flex-col">
                            <div class="tw-mb-2 tw-flex">
                                <button
                                    mat-button
                                    type="button"
                                    aria-label="add-carrier-exchange"
                                    [matTooltip]="
                                        'OUTGOING-GOODS.NEW-BUNDLE.SWAP.ADD-MORE-LOAD-CARRIER'
                                            | translate
                                    "
                                    (click)="addCarrierExchange()">
                                    <mat-icon
                                        fontSet="material-symbols-outlined"
                                        >add</mat-icon
                                    >
                                    <span class="text-primary">{{
                                        'OUTGOING-GOODS.NEW-BUNDLE.SWAP.ADD-LOAD-CARRIER'
                                            | translate
                                    }}</span>
                                </button>
                                &nbsp;
                                <button
                                    *ngIf="isConsignee"
                                    class="me-2"
                                    mat-button
                                    [disabled]="
                                        deliveryNote.attachmentsCount >= 30
                                    "
                                    type="button"
                                    matTooltip="{{
                                        'OUTGOING-GOODS.NEW-BUNDLE.SWAP.ATTACHMENTS-SCHEIN.TOOLTIP'
                                            | translate
                                    }}"
                                    (click)="palletTicketFileInput.click()">
                                    <mat-icon
                                        fontSet="material-symbols-outlined"
                                        >attach_file</mat-icon
                                    >
                                    <span class="text-primary">{{
                                        'OUTGOING-GOODS.NEW-BUNDLE.SWAP.ATTACHMENTS-SCHEIN.LABEL'
                                            | translate
                                    }}</span>
                                </button>

                                <button
                                    class="me-2"
                                    mat-button
                                    type="button"
                                    [disabled]="
                                        deliveryNote.attachmentsCount >= 30
                                    "
                                    matTooltip="{{
                                        'OUTGOING-GOODS.NEW-BUNDLE.SWAP.ATTACHMENTS-PHOTO.TOOLTIP'
                                            | translate
                                    }}"
                                    (click)="attachmentFileInput.click()">
                                    <mat-icon
                                        fontSet="material-symbols-outlined"
                                        >attach_file</mat-icon
                                    >
                                    <span class="text-primary">{{
                                        'OUTGOING-GOODS.NEW-BUNDLE.SWAP.ATTACHMENTS-PHOTO.LABEL'
                                            | translate
                                    }}</span>
                                </button>
                            </div>
                            <div>
                                <mat-chip-listbox
                                    class="d-inline-flex"
                                    [attr.aria-label]="
                                        'features.tickets.list.active-ticket.attachments'
                                            | translate
                                    ">
                                    <mat-chip-option
                                        *ngFor="
                                            let dpl of dplAttachments;
                                            let i = index
                                        "
                                        [selectable]="false"
                                        [removable]="true"
                                        (removed)="
                                            removeAttachment(
                                                i,
                                                dpl.attachmentInfo
                                            )
                                        ">
                                        <mat-icon
                                            *ngIf="
                                                dpl.attachmentInfo.contentType
                                                    | includes: 'pdf'
                                            "
                                            [matTooltip]="
                                                'OUTGOING-GOODS.NEW-BUNDLE.SWAP.ATTACHMENTS.SHOW-PDF'
                                                    | translate
                                            "
                                            (click)="
                                                downloadAttachment(
                                                    dpl.attachmentInfo._key,
                                                    dpl.attachmentInfo
                                                        .contentType
                                                )
                                            "
                                            fontSet="material-symbols-outlined"
                                            class="has-pointer"
                                            >description</mat-icon
                                        >

                                        <mat-icon
                                            *ngIf="
                                                dpl.attachmentInfo.contentType
                                                    | includes: 'image'
                                            "
                                            [matTooltip]="
                                                'OUTGOING-GOODS.NEW-BUNDLE.SWAP.ATTACHMENTS.SHOW-IMAGE'
                                                    | translate
                                            "
                                            (click)="
                                                downloadAttachment(
                                                    dpl.attachmentInfo._key,
                                                    dpl.attachmentInfo
                                                        .contentType
                                                )
                                            "
                                            fontSet="material-symbols-outlined"
                                            class="has-pointer"
                                            >file_copy</mat-icon
                                        >
                                        <span
                                            *ngIf="dpl.attachmentInfo.fileName"
                                            class="filename"
                                            >{{ dpl.attachmentInfo.fileName }}
                                        </span>
                                        <mat-icon
                                            matChipRemove
                                            [matTooltip]="
                                                'OUTGOING-GOODS.NEW-BUNDLE.SWAP.ATTACHMENTS.DELETE-ATTACHMENT'
                                                    | translate
                                            "
                                            fontSet="material-symbols-outlined"
                                            >cancel</mat-icon
                                        >
                                    </mat-chip-option>
                                </mat-chip-listbox>

                                <div class="tw-pt-4">
                                    <app-attachment-counter
                                        [deliveryNote]="
                                            deliveryNote
                                        "></app-attachment-counter>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="align-content-end">
                        <button
                            type="submit"
                            mat-flat-button
                            color="primary"
                            aria-label="save-carrier-exchange"
                            *ngIf="
                                loadCarrierSwapForm.valid ||
                                loadCarrierSwapForm.invalid
                            "
                            [disabled]="
                                loadCarrierSwapForm.invalid || formSaved
                            ">
                            <span *ngIf="!formSaved">{{
                                'OUTGOING-GOODS.NEW-BUNDLE.SWAP.SAVE'
                                    | translate
                            }}</span>
                            <span *ngIf="formSaved">{{
                                'OUTGOING-GOODS.NEW-BUNDLE.SWAP.SAVED'
                                    | translate
                            }}</span>
                        </button>
                    </div>
                </div>
            </form>
        </ng-container>
    </mat-expansion-panel>
</div>
<input
    class="d-none"
    multiple
    #attachmentFileInput
    type="file"
    (change)="selectAttachmentFile($event)"
    accept="image/heic,image/heif,image/png,image/jpeg" />

<input
    class="d-none"
    multiple
    #palletTicketFileInput
    type="file"
    (change)="selectPalletTicketFile($event)" />

<ng-template #pdfDialog>
    <mat-dialog-content>
        <div *ngIf="blobData && (dplContentType | includes: 'pdf')">
            <ngx-extended-pdf-viewer
                [src]="blobData"
                [height]="'100%'"
                [showToolbar]="false"
                [showBorders]="false">
            </ngx-extended-pdf-viewer>
        </div>
        <div *ngIf="imgSrcData && (dplContentType | includes: 'image')">
            <img
                [src]="imgSrcData"
                alt="image" />
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <span matDialogClose>{{ 'GLOBAL.BUTTON.CLOSE' | translate }}</span>
    </mat-dialog-actions>
</ng-template>
