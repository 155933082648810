import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';

/**
 * This function returns a ValidatorFn that checks if the values of two form controls are equal, i.e., they have the same value.
 * The function is used to validate the format of an input control in your form by comparing its value with another form control's (specified as 'matchingControlName') value.
 * @param {string} controlName - The name of the first control whose value we want to compare.
 * @param {string} matchingControlName - The name of the second control whose value we want to compare with.
 * @returns {(formGroup: UntypedFormGroup) => void} - A function that validates the form group, setting an error on 'matchingControlName' if its value doesn't match 'controlName', and null otherwise.
 */
export function confirmedEqualValidator(
    controlName: string,
    matchingControlName: string
) {
    return (formGroup: UntypedFormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];
        if (
            matchingControl.errors &&
            !matchingControl.errors.confirmedValidator
        ) {
            return;
        }
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ confirmedValidator: true });
        } else {
            matchingControl.setErrors(null);
        }
    };
}

/**
 * This function returns a ValidatorFn that checks if the provided control value is equal to the specified value 's'.
 * The function is used to validate the format of an input control in your form, ensuring it matches the specified value.
 * @param {any} s - The value against which we want to compare the control's value.
 * @returns {{[key: string]: any} | null} - An object containing 'isEqual' key and error message if the validation fails, or null if it passes.
 */
export function isEqualValidator(s: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const isEq = s == control.value ? true : false;
        return isEq ? null : { isEqual: true };
    };
}

/**
 * This function returns a ValidatorFn that checks if the provided control value contains at least one numeric digit.
 * The function is used to validate the format of an input control in your form, ensuring it includes at least one numeric digit.
 *   @param {AbstractControl} control - The control (input field) whose value we want to validate.
 *   @returns {{[key: string]: any} | null} - An object containing 'hasNumber' key and error message if the validation fails, or null if it passes.
 */
export function getNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const hasNumber = /\d/.test(control.value);
        return hasNumber ? null : { hasNumber: true };
    };
}

/**
 * This function returns a ValidatorFn that checks if the provided control value contains at least one uppercase letter.
 * The function is used to validate the format of an input control in your form, ensuring it includes at least one uppercase letter.
 *  @param {AbstractControl} control - The control (input field) whose value we want to validate.
 *  @returns {{[key: string]: any} | null} - An object containing 'hasUpper' key and error message if the validation fails, or null if it passes.
 */
export function getUpperValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const hasUpper = /[A-Z]/.test(control.value);
        return hasUpper ? null : { hasUpper: true };
    };
}

/**
 * This function returns a ValidatorFn that checks if the provided control value contains at least one lowercase letter.
 * The function is used to validate the format of an input control in your form, ensuring it includes at least one lowercase letter.
 * @param {AbstractControl} control - The control (input field) whose value we want to validate.
 * @returns {{[key: string]: any} | null} - An object containing 'hasLower' key and error message if the validation fails, or null if it passes.
 */
export function getLowerValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const hasLower = /[a-z]/.test(control.value);
        return hasLower ? null : { hasLower: true };
    };
}

/**
 * This function returns a ValidatorFn that checks if the provided control value contains any special character.
 * The function is used to validate the format of an input control in your form, ensuring it only contains alphanumeric characters (both uppercase and lowercase).
 * @param {AbstractControl} control - The control (input field) whose value we want to validate.
 * @returns {{[key: string]: any} | null} - An object containing 'hasSpecialChar' key and error message if the validation fails, or null if it passes.
 */
export function getSpecialCharValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const hasSpecialChar = /[^A-Za-z0-9]/.test(control.value);
        return hasSpecialChar ? null : { hasSpecialChar: true };
    };
}

/**
 * This function returns a ValidatorFn that checks if the provided control value contains only alphanumeric characters and is between 3 to 60 characters long.
 * The function is used to validate the format of a delivery note number input control in your form.
 * @param {AbstractControl} control - The control (input field) whose value we want to validate.
 * @returns {{[key: string]: any} | null} - An object containing 'hasSpecialChar' key and error message if the validation fails, or null if it passes.
 */
export function deliveryNoteNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const hasSpecialChar = /^[A-Za-z0-9]{3,60}$/.test(control.value);
        return hasSpecialChar ? null : { hasSpecialChar: true };
    };
}

/**
 * This function returns a ValidatorFn that checks if the provided control value contains only numeric characters and is between 3 to 60 characters long.
 * The function is used to validate the format of an order number input control in your form.
 * @param {AbstractControl} control - The control (input field) whose value we want to validate.
 * @returns {{[key: string]: any} | null} - An object containing 'hasSpecialChar' key and error message if the validation fails, or null if it passes.
 */
export function orderNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const hasSpecialChar = /^[0-9]{3,60}$/.test(control.value);
        return hasSpecialChar ? null : { hasSpecialChar: true };
    };
}

/**
 * This function returns a ValidatorFn that checks if the provided control value matches a specific pattern.
 * The pattern it checks against is 'M.XX-XXX-XX-XXXX or T.XX-XXX-XX-XXXX', where 'M' and 'T' stand for any uppercase letter,
 * 'XX' stands for two uppercase letters, 'XXX' stands for three uppercase letters, 'XX' stands for two digits, and 'XXXX' stands for four alphanumeric characters.
 * The function is used to validate the format of a bundle or tour number input control in your form.
 * @param {AbstractControl} control - The control (input field) whose value we want to validate.
 * @returns {{[key: string]: any} | null} - An object containing 'customPatternError' key and error message if the validation fails, or null if it passes.
 */
export function bundleorTourNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const isValid =
            /^[MT](\.[A-Z0-9]{0,2}(-[A-Z0-9]{0,3}(-[A-Z0-9]{0,2}(-[A-Z0-9]{0,4})?)?)?)?$/.test(
                control.value
            );
        return isValid
            ? null
            : {
                  customPatternError:
                      'The value must match the pattern M.XX-XXX-XX-XXXX or T.XX-XXX-XX-XXXX.',
              };
    };
}
