/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ConsigneeDeliveryNotesInPool } from '../../models/consignee-delivery-notes-in-pool';
import { KeyType } from '../../models/key-type';

export interface GetConsigneeDeliveryNotesMetadataInPool$Params {
  organizationSiteKey: KeyType;
}

export function getConsigneeDeliveryNotesMetadataInPool(http: HttpClient, rootUrl: string, params: GetConsigneeDeliveryNotesMetadataInPool$Params, context?: HttpContext): Observable<StrictHttpResponse<ConsigneeDeliveryNotesInPool>> {
  const rb = new RequestBuilder(rootUrl, getConsigneeDeliveryNotesMetadataInPool.PATH, 'get');
  if (params) {
    rb.path('organizationSiteKey', params.organizationSiteKey, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ConsigneeDeliveryNotesInPool>;
    })
  );
}

getConsigneeDeliveryNotesMetadataInPool.PATH = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes';
