import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast.service';
import { CustomTranslateService } from '@app/core/services/translate.service';
import { OrgType } from '@app/shared/components/delivery-notes/delivery-notes.component';
import { ShowPdfDialogComponent } from '@app/shared/components/show-pdf-dialog/show-pdf-dialog.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import {
    DeliveryNote,
    GetConsigneeTour,
    PostTourRequest,
    TourResponse,
} from 'api/models';
import {
    FineDiscrepanciesService,
    PdfService,
    ToursService,
} from 'api/services';
import { NgxSpinnerService } from 'ngx-spinner';
import { lastValueFrom } from 'rxjs';
import { ConsigneeLoadCarrierFilesService } from '../../../../../api/services/consignee-load-carrier-files.service';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-tour-delivery-notes',
    templateUrl: './tour-delivery-notes.component.html',
    styleUrls: ['./tour-delivery-notes.component.scss'],
})
export class TourDeliveryNotesComponent implements OnInit, OnDestroy {
    isAlive = true;

    @Input() orgType: OrgType = OrgType.Consignor;
    @Input() tourKey: string;
    @Input() tourOverview: TourResponse;
    @Input() readOnly: boolean = false;
    @Input() organizationSiteKey: string;
    @Output() tourLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() noteDeleted: EventEmitter<string> = new EventEmitter<string>();

    tour?: GetConsigneeTour;
    srcFile: Blob;
    currentLanguageCode: string;

    isConsignee: boolean = false;

    deliveryNoteKeyForFineDiscrepancyUpload: string;
    public selectedFineDiscrepancyFiles: File[] = [];

    constructor(
        private customTranslateService: CustomTranslateService,
        private spinner: NgxSpinnerService,
        private toastService: ToastService,
        private consigneeLoadCarrierService: ConsigneeLoadCarrierFilesService,
        private toursService: ToursService,
        private ref: ChangeDetectorRef,
        private translateService: TranslateService,
        private fineDiscrepancieService: FineDiscrepanciesService,
        private pdfService: PdfService,
        private dialog: MatDialog
    ) {
        this.currentLanguageCode = this.customTranslateService.currentLang;
    }

    async ngOnInit() {
        this.isConsignee = this.orgType === OrgType.Consignee;
        await this.getDeliveryNotes();
    }

    ngOnDestroy() {
        this.isAlive = false;
    }

    /**
     * Gets all delivery notes of the DLS folder.
     */
    async getDeliveryNotes() {
        await this.spinner.show();
        try {
            if (this.orgType === OrgType.Consignee) {
                this.tour = await lastValueFrom(
                    this.toursService.getConsigneeTour({
                        organizationSiteKey: this.organizationSiteKey,
                        tourKey: this.tourKey,
                    })
                );
            }

            if (this.orgType === OrgType.Carrier) {
                this.tour = await lastValueFrom(
                    this.toursService.getTour({
                        organizationSiteKey: this.organizationSiteKey,
                        tourKey: this.tourKey,
                    })
                );
            }

            this.tourLoaded.emit(true);
            this.ref.detectChanges();
        } catch (error) {
        } finally {
            await this.spinner.hide();
        }
    }

    /**
     *
     * @param deliveryNoteKey
     */
    async deleteDeliveryNote(deliveryNoteKey: string) {
        this.tour?.deliveryNotes.splice(
            this.tour?.deliveryNotes.findIndex(
                note => note._key === deliveryNoteKey
            ),
            1
        );

        let tourName = this.tour?.externalTourName;
        if (!tourName) {
            tourName = '';
        }

        const tour: PostTourRequest = {
            deliveryNoteKeys: this.tour?.deliveryNotes.map(note => note._key)!,
            externalTourName: tourName,
        };
        await this.toursService
            .updateTour({
                organizationSiteKey: this.organizationSiteKey,
                tourKey: this.tourKey,
                body: tour,
            })
            .pipe(untilDestroyed(this))
            .subscribe({
                next: response => {
                    this.toastService.success(
                        this.translateService.instant(
                            'SPEDITEUR.TOASTS.DELIVERY-NOTE-DELETED'
                        )
                    );
                    this.noteDeleted.emit(deliveryNoteKey);
                    this.getDeliveryNotes();
                },
                error: err => {
                    if (err.status == 409) {
                        this.toastService.error(
                            this.translateService.instant(
                                'SPEDITEUR.TOASTS.DELIVERY-NOTE-DELETED-FAILED-REASON'
                            )
                        );
                    } else {
                        this.toastService.error(
                            this.translateService.instant(
                                'SPEDITEUR.TOASTS.DELIVERY-NOTE-DELETED-FAILED'
                            )
                        );
                    }
                    this.getDeliveryNotes();
                },
            });
    }

    async selectFineDiscrepancyFile(event: any) {
        const files = event.target.files;
        for (const file of files) {
            const filename: string = file.name;
            if (
                !this.checkIfAppendixFileAlreadyExists(
                    this.deliveryNoteKeyForFineDiscrepancyUpload,
                    filename
                )
            ) {
                try {
                    await lastValueFrom(
                        this.fineDiscrepancieService.postNewFineDiscrepancy({
                            organizationSiteKey: this.organizationSiteKey,
                            deliveryNoteKey:
                                this.deliveryNoteKeyForFineDiscrepancyUpload,
                            fileName: filename,
                            body: file,
                        })
                    );
                    this.toastService.success(
                        this.translateService.instant(
                            'INCOMING-GOODS.CHECKIN.FINE-DISCREPANCY-ADDED'
                        )
                    );
                    this.selectedFineDiscrepancyFiles.push(file);
                    const note = this.tour?.deliveryNotes.find(
                        note =>
                            note._key ===
                            this.deliveryNoteKeyForFineDiscrepancyUpload
                    );
                    if (note) {
                        note.fineDiscrepancies = true;
                    }
                } catch (error) {
                    this.toastService.error(
                        this.translateService.instant(
                            'INCOMING-GOODS.CHECKIN.FINE-DISCREPANCY-ADDED-ERROR'
                        )
                    );
                }
            }
        }
    }

    checkIfAppendixFileAlreadyExists(
        deliveryNoteKey: string,
        filename: string
    ): boolean {
        let exists = false;
        if (this.selectedFineDiscrepancyFiles.length > 0) {
            exists = this.selectedFineDiscrepancyFiles.some(
                f => f.name === filename
            );
        }
        return exists;
    }

    async viewDeliveryNote(deliveryNote: DeliveryNote) {
        let blobData: Blob | undefined;
        await this.spinner.show();
        try {
            if (this.orgType === OrgType.Consignee) {
                blobData = await lastValueFrom(
                    this.pdfService.getConsigneeDeliveryNoteCombinedPdf({
                        organizationSiteKey: this.organizationSiteKey,
                        deliveryNoteKey: deliveryNote._key,
                    })
                );
                /*blobData = await lastValueFrom(
                    this.pdfService.getConsigneeDeliveryNoteFromPool({
                        deliveryNoteKey: deliveryNote._key,
                        organizationSiteKey: this.organizationSiteKey,
                    })
                );*/
            }

            if (this.orgType === OrgType.Carrier) {
                blobData = await lastValueFrom(
                    this.pdfService.getCarrierDeliveryNoteCombinedPdf({
                        organizationSiteKey: this.organizationSiteKey,
                        deliveryNoteKey: deliveryNote._key,
                    })
                );
                /*blobData = await lastValueFrom(
                    this.pdfService.getCarrierDeliveryNoteCombinedPdf({
                        deliveryNoteKey: deliveryNote._key,
                        organizationSiteKey: this.organizationSiteKey,
                    })
                );*/
            }

            await this.spinner.hide();
            this.dialog.open(ShowPdfDialogComponent, {
                minWidth: '80%',
                height: '95%',
                disableClose: true,
                data: {
                    data: blobData,
                },
            });
        } catch (error) {
            this.translateService.instant('ERROR.DOWNLOAD-NOTE');
        }
    }
}
